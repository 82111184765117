import { notif } from "@mailbrew/uikit";
import { createSlice } from "@reduxjs/toolkit";
import { STEP_INTERESTS } from "components/onboarding/steps";
import { loggedOutRedirectURL } from "hoc/loggedInPage";
import Router from "next/router";
import notifApiError from "utils/notifApiError";
import urls from "../urls";
import mixpanel from "mixpanel-browser";
import { handleNetworkError } from "../utils/networkErrors";
import { resetError, resetErrors, resetMessage, setError, setLoading, setMessage } from "./appReducer";
import { setLoading as setLoadingSettings } from "./settingsReducer";
import error from "next/error";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
  },
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
      // need this for mixpanel events (sent only if user is set)
      window.user = action.payload;
    },
  },
});

const { actions, reducer } = authSlice;

export const { setUser } = actions;

export const authUserSelector = (state) => state.auth.user;
export const subscribingSelector = (state) => state.monetization.subscribing;
export const monetizationSuccessSelector = (state) => state.monetization.success;
export const userTwitterAccountSelector = (state) =>
  state.auth.user?.connected_accounts?.find((acc) => acc.type === "twitter");

export default reducer;

export function register(formData) {
  return async function (dispatch, getState, { api }) {
    const {
      first_name,
      last_name,
      email,
      username,
      password,
      timezone,
      payment_method_id,
      plan_id,
      invite_code,
      role,
      source,
      source_text,
      coupon,
      utm_campaign,
      utm_source,
      utm_medium,
      aff,
      twitterSignupId,
    } = formData;

    dispatch(
      resetErrors([
        "signup:first_name",
        "signup:last_name",
        "signup:email",
        "signup:username",
        "signup:password",
        "signup:timezone",
        "signup",
      ])
    );

    dispatch(setLoading(true));

    // get recaptcha token
    let recaptchaToken;

    try {
      recaptchaToken = await window.grecaptcha.execute("6Lf1DOgUAAAAAKv3H2vmnsRkr0oze_u_PP8701MV", {
        action: "signup",
      });
    } catch (err) {
      dispatch(setLoading(false));
      dispatch(setError("signup", err?.toString() ?? "Recaptcha error."));
      return;
    }

    try {
      const res = await api.post("/register/", {
        user: {
          first_name,
          last_name,
          email,
          username,
          password,
          timezone,
          profile: {
            role: role || "",
            source: source || "",
            source_text: source_text || "",
            coupon: coupon || "",
          },
        },
        payment_method_id,
        plan_id,
        invite_code,
        twitter_signup_id: twitterSignupId,
        recaptcha_token: recaptchaToken,
        utm_campaign: utm_campaign || "",
        utm_source: utm_source || "",
        utm_medium: utm_medium || "",
        aff: aff || "",
      });

      mixpanel.identify(res.data.user.id);
      dispatch(setUser(res.data.user));
      Router.replace(urls.onboardingStepLink(STEP_INTERESTS));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);

      notifApiError(err, "Correct the invalid fields");

      if (err.response?.data.detail) {
        dispatch(setError("signup", err.response.data.detail));
      } else {
        const errors = err.response?.data;
        if (!errors) return;
        for (const key in errors) {
          dispatch(setError(`signup:${key}`, err.response.data[key].join("\n")));
        }
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function loginWithToken(token, redirectAfterLoginPath) {
  return async function (dispatch, getState, { api }) {
    api
      .post("/login_with_token/", { token })
      .then((res) => {
        const user = res.data;
        dispatch(setUser(user));
        Router.push(redirectAfterLoginPath || "/");
      })
      .catch((err) => {
        const msg = err?.response?.data?.detail;
        alert(`${msg ?? err}`);
      });
  };
}

export function login(email, password, redirectAfterLoginPath) {
  return async function (dispatch, getState, { api }) {
    const updateError = () => dispatch(setError("login", "Wrong email or password, please try again."));

    dispatch(setLoading(true));
    try {
      const res = await api.post("/login/", { email, password });

      if (res) {
        const user = res.data;
        await dispatch(setUser(user));

        if (redirectAfterLoginPath && redirectAfterLoginPath.startsWith("http")) {
          window.location.href = redirectAfterLoginPath;
        } else {
          Router.push(redirectAfterLoginPath || "/");
        }
      } else {
        // when wrong credentials server responds with 401 and , for some reason, res is null
        updateError();
      }
    } catch (err) {
      const handled = handleNetworkError(err);
      if (handled) return;
      updateError();
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function updateMe() {
  return async function (dispatch, getState, { api }) {
    try {
      const res = await api.get("/me/");
      dispatch(setUser(res.data));
    } catch (error) {
      // unauthorized
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        dispatch(logout({ forced: true }));
        return;
      } else {
        notifApiError(error, "Error updating user data");
      }
    }
  };
}

const logoutDefaultOptions = { forced: false, redirect: true, next: null };

export function logout(options) {
  options = { ...logoutDefaultOptions, ...options };

  return async function (dispatch, getState, { api }) {
    try {
      await api.post("/logout/");
      await dispatch(setUser(null));
      localStorage.clear();

      if (window.location.pathname !== urls.login()) {
        if (options.next) {
          Router.push(urls.login(options.next));
        } else if (options.forced) {
          Router.push(loggedOutRedirectURL());
        } else {
          Router.push(urls.login());
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("Error logging out:", err);
    }
  };
}

export function updateUserFrontendState(updates) {
  return async function (dispatch, getState, { api }) {
    try {
      const res = await api.post("/update_user_frontend_state/", updates);
      dispatch(setUser(res.data));
    } catch {
      notif.error("Server error");
    }
  };
}

export function deleteAccount() {
  return async function (dispatch, getState, { api }) {
    dispatch(setLoadingSettings("deleteAccount", true));
    try {
      await api.post("/delete_user/");
      dispatch(logout());
    } catch {
      notif.error("Error while deleting your account, please contact support.");
    } finally {
      dispatch(setLoadingSettings("deleteAccount", false));
    }
  };
}

export function requestPasswordResetLink(email) {
  return async function (dispatch, getState, { api }) {
    dispatch(resetError("reset_password"));
    dispatch(resetMessage("reset_password"));

    try {
      dispatch(setLoading(true));
      await api.post("/ask_reset_password/", { email });
      dispatch(setMessage("reset_password", "Done! We have sent a password reset link to the email you provided."));
    } catch (error) {
      dispatch(
        setError("reset_password", error?.response?.data?.detail || "Error resetting your password. Try again.")
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function resetPassword(token, password) {
  return async function (dispatch, getState, { api }) {
    dispatch(resetError("reset_password"));

    try {
      dispatch(setLoading(true));
      const res = await api.post("/reset_password/", { token, password });

      dispatch(setUser(res.data.user));
      Router.replace("/");
    } catch (error) {
      dispatch(
        setError("reset_password", error?.response?.data?.detail || "Error resetting your password. Try again.")
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function updateBio(userId, bio) {
  return async function (dispatch, getState, { api }) {
    try {
      await api.patch(`/profile/${userId}/`, { bio });
      await dispatch(updateMe());
    } catch (error) {
      dispatch(
        setError(
          "update_bio",
          error?.response?.data?.bio || error?.response?.data?.detail || "Error updating your bio. Please, try again."
        )
      );
    }
  };
}
