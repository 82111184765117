import { urlNameForStep } from "./components/onboarding/steps";

// prettier-ignore
const urls = {
  brews: () => `/`,
  manageBrews: () => `/manage`,
  saved: () => `/saved`,
  savedArchive: () => `/saved/archive`,
  login: (next = null) => (!next ? `/login` : `/login?next=${next}`),
  logout: (next = null) => (!next ? `/logout` : `/logout?next=${next}`),
  signup: (ref = null) => (!ref ? `/signup` : `/signup?ref=${ref}`),
  signupDetails: () => `/signup/details`,
  settings: () => `/settings`,
  settings_accounts: () => `/settings/accounts`,
  settings_billing: () => `/settings/billing`,
  pricing: () => `/pricing`,
  upgrade: () => `/upgrade`,
  affiliate: () => `/affiliate`,
  affiliateTerms: () => `/affiliate/terms`,
  loginAs: () => `/login_as`,
  resetTrial: () => `/restart_trial`,
  subscribed: (share_url) => `/${share_url}/subscribed`,
  unsubscribed: (share_url) => `/${share_url}/unsubscribed`,
  editNewsletter: (id) => `/edit/${id}`,
  editNewsletterSource: (id, sourceIndex) => `/edit/${id}/${sourceIndex}`,
  manageSubscribers: (id) => `/edit/${id}/subscribers`,
  viewNewsletterIssue: (share_url, issue_number) => `/${share_url}/${issue_number}`,
  viewNewsletter: (share_url) => `/${share_url}`,
  embedNewsletter: (share_url) => `/${share_url}/embed`,
  profile: (username) => `/${username}`,
  askResetPasswordLink: () => `/reset_password`,
  useResetPasswordLink: (token) => `/reset_password/${token}`,

  // ONBOARDING
  onboarding: () => `/welcome`,
  // ... new onboarding
  onboardingPickPlan: () => `/welcome/plan`,

  // ... old onboarding. TODO: remove this if we deprecate it.
  onboardingTypefully: () => `/typefully`,
  typefullyAuth: () => `/typefully/auth`,
  onboardingTypefullyStep: (step) => `/typefully/${urlNameForStep(step)}`,
  onboardingStepLink: (step) => `/welcome/${urlNameForStep(step)}`,


  inbox: () => `/inbox`,
  inboxArchive: () => `/inbox/archive`,
  manageInbox: () => `/inbox/manage`,
  manageInboxBlocked: () => `/inbox/manage/screened-out`,
};

export default urls;
