export const STEP_INTERESTS = "interests";
export const STEP_RSS = "rss";
export const STEP_REDDIT = "reddit";
export const STEP_TWITTER_BREW = "twitter_brew";
export const STEP_INBOX = "inbox";
export const STEP_LATER = "later";
export const STEP_MORE_SOURCES = "more_sources";
export const STEP_SCHEDULE = "schedule";

/**
 * Ordered inbox steps. The value is the pretty step name that appears in the URL `/welcome/${pretty-name}`.
 */
const onboardingSteps = {
  [STEP_INTERESTS]: "",
  [STEP_RSS]: "blogs",
  [STEP_REDDIT]: "reddit",
  [STEP_TWITTER_BREW]: "twitter",
};

const typefullySteps = {
  [STEP_TWITTER_BREW]: "twitter",
};

export function urlNameForStep(step) {
  return onboardingSteps[step];
}

export function stepFromUrlName(stepUrlName) {
  if (!stepUrlName) return STEP_INTERESTS;

  for (const step in onboardingSteps) {
    if (onboardingSteps[step] === stepUrlName) return step;
  }

  return null;
}

export function nextOnboardingStep(currentStep, typefully = false) {
  const steps = Object.keys(typefully ? typefullySteps : onboardingSteps);
  const currStepIndex = steps.findIndex((step) => step === currentStep);
  if (currStepIndex === steps.length - 1) {
    // last inbox step
    return null;
  }
  return steps[currStepIndex + 1];
}
